import {Observable, Subject, Subscription} from 'rxjs';

export abstract class SyncBaseService {
    protected doneSubject: Subject<boolean> = new Subject<boolean>();
    public done$: Observable<boolean> = this.doneSubject.asObservable();
    protected syncInProgress = false;
    protected requestSubscription: Subscription | null = null;

    public abstract startSyncing(): Promise<void>;

    public async cancelSyncing() {
        if (this.syncInProgress) {
            if (this.requestSubscription !== null) {
                this.requestSubscription.unsubscribe();
            }
            while (this.syncInProgress) {
                await (new Promise(resolve => setTimeout(resolve, 500)));
            }
        }
    }
}
