import { Injectable } from '@angular/core';
import { HttpService } from '@shared/http';
import { AuthenticationResult, AuthenticationResultDto, RefreshTokenProviderBase } from '@shared/token-interceptor';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RefreshTokenProvider extends RefreshTokenProviderBase {
    private path = 'auth';
    private AUTH_KEY = 'AUTH_KEY';

    constructor(private httpService: HttpService, private authenticationResult: AuthenticationResult) {
        super();
    }

    public refreshToken(authenticationResult: AuthenticationResultDto): Observable<string> {
        return this.httpService
            .makePost<AuthenticationResultDto, AuthenticationResultDto>(`${this.path}/refresh`, authenticationResult)
            .pipe(
                map((tokenAndRefreshToken: AuthenticationResultDto) => {
                    this.authenticationResult.setTokenAndRefreshToken(
                        tokenAndRefreshToken.token,
                        tokenAndRefreshToken.refreshToken
                    );
                    localStorage.setItem(this.AUTH_KEY, JSON.stringify(this.authenticationResult.getData()));

                    return tokenAndRefreshToken.token;
                }),
                catchError((error) => {
                    localStorage.removeItem(this.AUTH_KEY);
                    return throwError(() => error);
                })
            );
    }
}
