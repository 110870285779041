import { Injectable } from '@angular/core';
import { AppointmentDocumentDto, OrderAppointmentDto, OrderDto, OrderService } from '@domain/appointment';
import {
    AppointmentActionRequired,
    OrderAppointment,
    OrderAppointmentsRepository,
    OrdersRepository,
} from '@domain/database';
import { fromUnixTime, getUnixTime, subYears, subMonths, subBusinessDays } from 'date-fns';
import { SyncBaseService } from './sync-base.service';
import { environment } from '../../../../environments/environment';
import { DataHelperService } from './data-helper.service';
import { DynatraceLoggerService } from './dynatrace-logger.service';
import { UserStorageService } from '@domain/auth';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class OrdersSyncService extends SyncBaseService {
    constructor(
        private ordersRepo: OrdersRepository,
        private orderAppointmentsRepo: OrderAppointmentsRepository,
        private orderService: OrderService,
        private dataHelperService: DataHelperService,
        private traceService: DynatraceLoggerService,
        private userStorageService: UserStorageService
    ) {
        super();
    }

    public async startSyncing(): Promise<void> {
        if (this.syncInProgress) {
            return;
        }
        this.doneSubject.next(false);
        this.syncInProgress = true;

        if (!environment.production) {
            // tslint:disable-next-line: no-console
            // console.time('/ORDERS api duration');
        }

        try {
            const maxModifiedUnix = await this.ordersRepo.getMaxModifiedUnix();
            let request = this.getModifiedDataFromApi(maxModifiedUnix);
            let promise = new Promise<OrderDto[]>((resolve, reject) => {
                this.requestSubscription = request.subscribe(
                    (next) => resolve(next),
                    (error) => reject(error)
                );
                this.requestSubscription.add(() => resolve([]));
            });
            let orders = await promise;
            if (!environment.production) {
                // tslint:disable-next-line: no-console
                // console.timeEnd('/ORDERS api duration');
                // tslint:disable-next-line: no-console
                // console.time('/ORDERS rxdb duration');
            }

            await this.syncLocalOrders(orders);
            for (const element of orders) {
                await this.syncLocalOrderDocuments(element);
            }
        } finally {
            this.requestSubscription.unsubscribe();
            this.requestSubscription = null;
            this.syncInProgress = false;
            this.doneSubject.next(true);
        }

        if (!environment.production) {
            // tslint:disable-next-line: no-console
            // console.timeEnd('/ORDERS rxdb duration');
        }
    }

    public async syncLocalOrders(items: OrderDto[]) {
        if (items.length === 0) {
            return;
        }

        let appointments: OrderAppointment[] = [];

        const orders = items.map((orderDetails: OrderDto) => {
            if (orderDetails.appointments) {
                appointments = appointments.concat(
                    orderDetails.appointments.map((appointment: OrderAppointmentDto) =>
                        this.dataHelperService.orderDTOtoOrderAppointmentMap(orderDetails, appointment)
                    )
                );
            }

            return this.dataHelperService.orderDTOToOrderMap(orderDetails);
        });

        this.traceService.log('INFO', `User ${this.userStorageService.getUsername()} syncing local orders`, 'Gimbil');

        await this.ordersRepo.bulkUpsert(orders);
        await this.orderAppointmentsRepo.bulkUpsert(appointments);
    }

    public async syncLocalOrderNotes(item: Partial<OrderDto>, isChangeNoteAllowed: boolean = false) {
        const orderId = `${item.retailOrderId}_${item.environment}`;

        const order = {
            isChangeNoteAllowed,
            modifiedUnix: getUnixTime(new Date(item.modified)),
            notes: item.notes
                ? item.notes.map((note) => {
                      return {
                          dateUnix: getUnixTime(new Date(note.date)),
                          id: note.id,
                          text: note.text ? note.text : undefined,
                          userName: note.userName ? note.userName : undefined,
                          noteTypeId: note.noteTypeId,
                      };
                  })
                : [],
        };

        await this.ordersRepo.updateOrderNotes(orderId, order);
    }

    public async syncLocalOrderAppointments(item: Partial<OrderDto>) {
        const orderId = `${item.retailOrderId}_${item.environment}`;

        const order = {
            isChangeNoteAllowed: item.isChangeNoteAllowed,
            isCabinetInstallIncomplete: item.isCabinetInstallIncomplete,
            cabinetInstallIncompleteReason: item.cabinetInstallIncompleteReason,
            modifiedUnix: getUnixTime(new Date(item.modified)),
            actionsRequired: item.actionsRequired
                ? item.actionsRequired.map((ar: AppointmentActionRequired) => {
                      return {
                          id: ar.id,
                          description: ar.description,
                          isOptional: ar.isOptional,
                      };
                  })
                : [],
            notes: item.notes
                ? item.notes.map((note) => {
                      return {
                          dateUnix: getUnixTime(new Date(note.date)),
                          id: note.id,
                          text: note.text ? note.text : undefined,
                          userName: note.userName ? note.userName : undefined,
                          noteTypeId: note.noteTypeId,
                      };
                  })
                : [],
        };

        const orderAppointments = item.appointments
            ? item.appointments.map((appointment: OrderAppointmentDto) => {
                  return this.dataHelperService.orderDTOtoOrderAppointmentMap(item, appointment);
              })
            : [];

        await this.ordersRepo.updateOrderNotes(orderId, order);
        await this.orderAppointmentsRepo.updateOrderAppointments(orderId, orderAppointments);
    }

    public async syncLocalOrderDocuments(item: Partial<OrderDto>) {
        const orderId = `${item.retailOrderId}_${item.environment}`;
        const order = {
            modifiedUnix: getUnixTime(new Date(item.modified)),
            documents: item.documents
                ? item.documents.map((doc: AppointmentDocumentDto) => {
                      return {
                          url: doc.url,
                          fileName: doc.fileName,
                          dateUnix: getUnixTime(new Date(doc.date)),
                          documentType: doc.documentType,
                          thumbnailUrl: doc.thumbnailUrl ? doc.thumbnailUrl : undefined,
                          documentId: doc.documentId,
                      };
                  })
                : [],
            actionsRequired: item.actionsRequired
                ? item.actionsRequired.map((ar: AppointmentActionRequired) => {
                      return {
                          id: ar.id,
                          description: ar.description,
                          isOptional: ar.isOptional,
                      };
                  })
                : [],
        };

        const actionsRequired = item.actionsRequired
            ? item.actionsRequired.map((ar: AppointmentActionRequired) => {
                  return {
                      id: ar.id,
                      description: ar.description,
                      isOptional: ar.isOptional,
                  };
              })
            : [];

        await this.ordersRepo.updateOrderDocuments(orderId, order);
        await this.orderAppointmentsRepo.updateOrderAppointmentsActionsRequired(orderId, actionsRequired);
    }

    private getModifiedDataFromApi(maxModifiedUnix: number | null) {
        // console.log('Max Modified', maxModifiedUnix);

        if (maxModifiedUnix) {
            return this.orderService.getAllOrders(undefined, fromUnixTime(maxModifiedUnix + 1));
        } else {
            const oneYearInThePast = subYears(new Date(), 1);
            return this.orderService.getAllOrders(oneYearInThePast, undefined);
        }
    }
}
