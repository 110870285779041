import { KindOfOrder, Order } from '@domain/database';

export enum AppointmentDateType {
    CustomerContactDate = 1,
    ReadyToMeasureTemplateDate = 2,
    ScheduledMeasureDate = 3,
    ActualMeasureDate = 4,
    ScheduledTemplateDate = 5,
    ScheduledInstallDate = 6,
    ActualInstallDate = 7,
    CabinetDate = 8,
    ScheduledDeliveryDate = 13,
    DeliveryDate = 14,
    EstimatedCabinetDeliveryDate = 17,
    CabinetPOReceivedDate = 18,
    ScheduledInstallStartDate = 20,
    ScheduledInstallCompletionDate = 21,
    ActualInstallStartDate = 22,
    ActualInstallCompletionDate = 23,
}

export enum WorkOrderAppointmentDateType {
    CustomerContactDate = 1,
    ScheduledDate = 2,
    WorkCompleteDate = 3,
}

export function appointmentDateTypeToString(appointmentDateType: AppointmentDateType, order: Order): string {
    if (order.kindOfOrder == KindOfOrder.WorkOrder) {
        switch (appointmentDateType.valueOf()) {
            case WorkOrderAppointmentDateType.CustomerContactDate:
                return 'CustomerContactDate';
            case WorkOrderAppointmentDateType.ScheduledDate:
                return 'ScheduledDate';
            case WorkOrderAppointmentDateType.WorkCompleteDate:
                return 'WorkCompleteDate';
        }
    }
    const isMeasureOrder = order.roTypeId === 144 || order.roTypeId === 160; //Measure / CabinetMeasureOrder
    switch (appointmentDateType) {
        case AppointmentDateType.CustomerContactDate:
            return 'CustomerContactDate';
        case AppointmentDateType.ReadyToMeasureTemplateDate:
            return 'ReadyToMeasureTemplateDate';
        case AppointmentDateType.ScheduledMeasureDate:
            return isMeasureOrder ? 'ScheduledMeasureDate' : 'MeasureDate';
        case AppointmentDateType.ActualMeasureDate:
            return 'ActualMeasureDate';
        case AppointmentDateType.ScheduledTemplateDate:
            return 'ScheduledTemplateDate';
        case AppointmentDateType.ScheduledInstallDate:
            return 'ScheduledInstallDate';
        case AppointmentDateType.ActualInstallDate:
            return 'ActualInstallDate';
        case AppointmentDateType.CabinetDate:
            return 'CabinetDate';
        case AppointmentDateType.ScheduledDeliveryDate:
            return 'ScheduledDeliveryDate';
        case AppointmentDateType.DeliveryDate:
            return 'DeliveryDate';
        case AppointmentDateType.EstimatedCabinetDeliveryDate:
            return 'EstimatedCabinetDeliveryDate';
        case AppointmentDateType.CabinetPOReceivedDate:
            return 'CabinetPOReceivedDate';
        case AppointmentDateType.ScheduledInstallStartDate:
            return 'ScheduledInstallStartDate';
        case AppointmentDateType.ScheduledInstallCompletionDate:
            return 'ScheduledInstallCompletionDate';
        case AppointmentDateType.ActualInstallStartDate:
            return 'ActualInstallStartDate';
        case AppointmentDateType.ActualInstallCompletionDate:
            return 'ActualInstallCompletionDate';
    }
}

export const DISPLAY_DATE = 'DDD, MMM D, YYYY';
export const DISPLAY_DATE_TIME = 'DDD, MMM D, YYYY @ hA';
export const PICKER_DATE = 'MMM D YYYY';
export const PICKER_DATE_TIME = 'MMM D YYYY H';
export const NO_HOURS = [];
export const TWO_HOURS = [8, 10, 12, 14, 16];
export const FOUR_HOURS = [8, 12, 16];

export function workOrderAppointmentDateTypeFormats(
    appointmentDateType: WorkOrderAppointmentDateType
): [string, string, number[]] {
    switch (appointmentDateType) {
        case WorkOrderAppointmentDateType.CustomerContactDate:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
        case WorkOrderAppointmentDateType.ScheduledDate:
            return [DISPLAY_DATE_TIME, PICKER_DATE_TIME, FOUR_HOURS];
        case WorkOrderAppointmentDateType.WorkCompleteDate:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
        default:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
    }
}

export function appointmentDateTypeFormats(appointmentDateType: AppointmentDateType): [string, string, number[]] {
    // Force everything to date only for the time being until a better picker option is available
    // return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];

    switch (appointmentDateType) {
        case AppointmentDateType.CustomerContactDate:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
        case AppointmentDateType.ReadyToMeasureTemplateDate:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
        case AppointmentDateType.ScheduledMeasureDate:
            return [DISPLAY_DATE_TIME, PICKER_DATE_TIME, TWO_HOURS];
        case AppointmentDateType.ActualMeasureDate:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
        case AppointmentDateType.ScheduledTemplateDate:
            return [DISPLAY_DATE_TIME, PICKER_DATE_TIME, TWO_HOURS];
        case AppointmentDateType.ScheduledInstallDate:
        case AppointmentDateType.ScheduledDeliveryDate:
            return [DISPLAY_DATE_TIME, PICKER_DATE_TIME, FOUR_HOURS];
        case AppointmentDateType.ActualInstallDate:
        case AppointmentDateType.DeliveryDate:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
        case AppointmentDateType.CabinetDate:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
        default:
            return [DISPLAY_DATE, PICKER_DATE, NO_HOURS];
    }
}
