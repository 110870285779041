import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
import { RefreshTokenProviderBase } from './refresh-token-provider-base';
import { TokenProvider } from './token-provider';
import { ApiErrorCodes, ErrorModel } from '@shared/http';
import { AlertService } from '@shared/alert';
import { AuthService } from '../../domain/auth/services/auth.service';

// TODO Fix circular dependency between AuthService

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private refreshTokenProvider: RefreshTokenProviderBase,
        private tokenProvider: TokenProvider,
        private alertService: AlertService,
        private authService: AuthService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (
                    (error && error.status !== 401) ||
                    request.url.indexOf('dynatrace.com') > -1 ||
                    request.url.indexOf('auth/authenticate') > -1 ||
                    request.url.indexOf('auth/refresh') > -1 ||
                    request.url.indexOf('auth/otp') > -1
                ) {
                    throw error;
                }
                if (this.refreshTokenInProgress) {
                    return this.refreshTokenSubject.pipe(
                        filter((result) => result !== null),
                        first(),
                        switchMap((t) => next.handle(this.addTokenToRequestHeader(request, t)))
                    );
                } else {
                    this.refreshTokenInProgress = true;
                    this.refreshTokenSubject.next(null);

                    return this.refreshTokenProvider.refreshToken(this.tokenProvider.getToken()).pipe(
                        switchMap((t: string) => {
                            this.refreshTokenInProgress = false;
                            this.refreshTokenSubject.next(t);
                            return next.handle(this.addTokenToRequestHeader(request, t));
                        }),
                        catchError(async (e: ErrorModel) => {
                            this.refreshTokenInProgress = false;
                            if (
                                e.errorCode === ApiErrorCodes.INVALID_REFRESH_TOKEN ||
                                e.errorCode === ApiErrorCodes.EXPIRED_REFRESH_TOKEN ||
                                e.errorCode === ApiErrorCodes.INVALID_TOKEN
                            ) {
                                await this.authService.logout();
                                this.showSessionExpiredAlert();
                            }
                            console.log(JSON.stringify(e));
                            throw e;
                        })
                    );
                }
            })
        );
    }

    private addTokenToRequestHeader(request, token) {
        return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token,
            },
        });
    }

    private showSessionExpiredAlert(): void {
        const okButton = {
            text: 'Ok',
            handler: () => {},
        };
        this.alertService.presentAlert('', 'Your session has expired. Please login again.', [okButton], true);
    }
}
