import { Injectable } from '@angular/core';
import { ApiErrorCodes, ErrorModel } from '../models';

@Injectable()
export class ApiExceptionHandlerService {
    private noToasterErrorCodes = [
        ApiErrorCodes.CHANGE_REQUEST_NOTE_ERROR,
        ApiErrorCodes.DATE_NOT_EDITABLE,
        ApiErrorCodes.ACTUAL_MEASURE_DATE_NOT_EDITABLE,
        ApiErrorCodes.CABINET_ACTUAL_MEASURE_DATE_NOT_EDITABLE,
        ApiErrorCodes.DATE_INVALID_FUTURE,
        ApiErrorCodes.DATE_INVALID_PAST,
        ApiErrorCodes.DATE_INVALID_RODATE,
        ApiErrorCodes.INVALID_OTP,
        ApiErrorCodes.INVALID_MODEL,
        ApiErrorCodes.INVALID_REFRESH_TOKEN,
        ApiErrorCodes.EXPIRED_REFRESH_TOKEN,
        ApiErrorCodes.INVALID_TOKEN,
    ];

    public handle(error: ErrorModel) {
        if (this.noToasterErrorCodes.includes(error.errorCode)) {
            throw error;
        }
    }
}
